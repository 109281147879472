<template>
  <v-container>
    <v-expansion-panels focusable>
      <v-expansion-panel
          v-for="routine in routines"
          :key="routine.id"
      >
        <v-expansion-panel-header>Item</v-expansion-panel-header>
        <v-expansion-panel-content class="pa-2">
          <v-img v-if="routine.photo" :src="routine.photo" style="width: 100%"></v-img>
          <div v-html="routine.body"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      routines: [],
    }
  },
  methods: {
    initialize() {
      const url = 'routines'
      axios.get(url).then((response) => {
        this.routines = response.data
      })
    }
  },
  created() {
    this.initialize()
  }
}
</script>